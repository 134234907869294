import { useState } from "react";
import "../styles/city.scss";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArtworkService from '../apis/ArtworkService';

import { useParams } from "react-router-dom";
import ZoomableImage from "../components/zoomableImage";

const queryClient = new QueryClient();
const artworkObject = new ArtworkService();

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

export default function City(props) {

  const { city } = useParams();

  return (
    <div className="page_container">
      <QueryClientProvider client={queryClient}>
        <GetData setHighlight={props.setHighlight} city={city}/>
      </QueryClientProvider>
    </div>
  );
}

function GetData(props) {
  const [paintings, setPaintings] = useState([]);  
  const [show, setShow] = useState(0);

  const { isLoading, error, data } = useQuery("repoData", async () => {
    const data = await artworkObject.getArtByCity(props.city);
    
    if (data){
      setPaintings(data)
    }
    
  });  
  
  if (isLoading || paintings.length === 0)
    return (
      <div>
        <MoonLoader loading={isLoading} css={override} size={50} />
      </div>
    );

  if (error)
    return (
      <div>
        <h1>Error, please contact epgarry1@gmail.com.</h1>
      </div>
    );

  return (
    <>
      <div className="header">
        <h1 className="titles">{paintings[show]["title"]}</h1>
      </div>
      <div className="carousel_container">
        <div className="back">
          <ArrowBackIosIcon
            onClick={() => {
              if (show === 0) {
                setShow(paintings.length - 1);
              } else {
                setShow(show - 1);
              }
            }}
          />
        </div>
        <div className="displayed_image">
          <ZoomableImage    
            style="city-img"         
            img_location={'https://arg-paintings.s3.us-east-2.amazonaws.com/' + paintings[show]["fileName"]}
            alt_text={paintings[show]["title"]}
          />
        </div>
        <div className="forward">
          <ArrowForwardIosIcon
            onClick={() => {
              if (show === paintings.length - 1) {
                setShow(0);
              } else {
                setShow(show + 1);
              }
            }}
          />
        </div>
      </div>
      <div className="attribute_container">
        <div>{paintings[show]["description"]}</div>
        <div>
          {paintings[show]["height"]} x {paintings[show]["width"]}
        </div>
        <div className="mt-5">{paintings[show]["comment"]}</div>
      </div>
    </>
  );
}
