import React from "react";
import "../styles/home.scss";
import { Link } from "react-router-dom";
import ZoomableImage from "../components/zoomableImage";

export default function Home() {
  
  return (
    <div className="page_container">
      <div className="name">
        <h1>Alan Robert Garry</h1>
      </div>

      <div className="home_carousel">        
      <ZoomableImage 
        style="home-img"
        img_location={process.env.PUBLIC_URL + "/home_page_painting.jpg"}
        alt_text="home page painting"
      />

      </div>
      <div className="create-link">
        <Link to="/create">Admin</Link>
      </div>

    </div>
  );
}

