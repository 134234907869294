import { useState } from "react";
import "../styles/portfolio.scss";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import { Link } from "react-router-dom";
import ArtworkService from "../apis/ArtworkService";

const queryClient = new QueryClient();
const artworkObject = new ArtworkService();

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
`;

export default function Portfolio(props) {
  return (
    <div className="">
      <QueryClientProvider client={queryClient}>
        <GetData setHighlight={props.setHighlight} />
      </QueryClientProvider>
    </div>
  );
}

function GetData() {
  const [paintings, setPaintings] = useState([]);

  const { isLoading, error, data } = useQuery("repoData", async () => {
    const data = await artworkObject.getPortfolioPage();

    if (data) {
      setPaintings(data);
    }

    if (isLoading)
      return (
        <div>
          <MoonLoader loading={isLoading} css={override} size={50} />
        </div>
      );

    if (error)
      return (
        <div>
          <h1>Error, please contact epgarry1@gmail.com.</h1>
        </div>
      );
  });

  return (
    <>
      <div className="portfolio-container">
        {paintings.map((elem, indx) => (
          <Link className="city-link" to={"/portfolio/" + elem.city} key={indx}>
            <div className="city-title">{elem.city}</div>
            <div className="portfolio-city-img-container">
              <img
                className="portfolio-img"
                src={
                  "https://arg-paintings.s3.us-east-2.amazonaws.com/" +
                  elem["fileName"]
                }
                loading="lazy"
              />
            </div>
          </Link>
        ))}
      </div>
    </>
  );
}
