import '../styles/zoom.css';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Zoom from 'react-medium-image-zoom'


export default function ZoomableImage(props) {
  return (
      
    <Zoom IconUnzoom={ZoomOutIcon} IconZoom={ZoomInIcon}>
        <img
          className={props.style}
          src={props.img_location}
          alt={props.alt_text}    
          loading="lazy"
        />
      </Zoom>
  );

}